import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/app/components/loader/loader.service';
import { ApiResponse } from 'src/app/constants/interfaces';
import { environment } from 'src/environments/environment';
// import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private _apiUrl!: string;
  constructor(private _http: HttpClient, private _loaderService: LoaderService) {
    this._apiUrl = environment.API_BASE_PATH;
  }
  post<T>(url: any, data: any, loader = true): Observable<any> {

    if (loader) {
      this._loaderService.show();
    }

    return this._http.post<any>(this._apiUrl + url, data);
  }
  get<T>(url: any, httpParams?: any, loader = true): Observable<ApiResponse<T>> {
    if (loader) {
      this._loaderService.show();
    }
    return this._http.get<ApiResponse<T>>(this._apiUrl + url, {
      params: httpParams,
    });
  }
  put<T>(url, data?, loader = true): Observable<ApiResponse<T>> {
    if (loader) {
      this._loaderService.show();
    }
    return this._http.put<ApiResponse<T>>(this._apiUrl + url, data);
  }

  delete<T>(url, data?, params?,loader = true): Observable<ApiResponse<T>> {
    if (loader) {
      this._loaderService.show();
    }
    return this._http.delete<ApiResponse<T>>(this._apiUrl + url, {
      body: data,
      params:params
    });
  }
}

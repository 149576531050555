const NOTIFICATION_BASE: string = 'notification';

const ADMIN_BASE: string = 'admin';
const BOOKING: string = 'booking';
const PAYMENT_SERVICE = "/payment/api/v1/";

const USER_SERVICE = 'admin/api/v1';
const USER_TYPE = `${USER_SERVICE}/${ADMIN_BASE}`;
const ORDER_BASE = `${USER_SERVICE}/order`;

const ORGANIZER_SERVICE = 'organizer/api/v1/organizer';

const APP_USER_SERVICE = `${USER_SERVICE}/user`;

export const IMAGE_UPLOAD_API = `${USER_SERVICE}/event/upload-media`;

/**
 * @CITY_NAME_AND_STATE_NAME
 */
export const CITY_API = `${USER_TYPE}/cities`;
export const STATE_API = `${USER_TYPE}/states`;

/**
 * @ACCOUNT_RELATED_END_POINTS
 */
export const LOGIN_API = `${USER_TYPE}/login`;
export const RESEND_OTP = `${USER_TYPE}/resend-otp`;

export const LOGOUT_API = `${USER_TYPE}/logout`;
export const FORGOT_PASSWORD_API = `${USER_TYPE}/check-email-for-forgot-password`;
export const RESET_PASSWORD_API = `${USER_TYPE}/reset-password`;
export const CHANGE_PASSWORD_API = `${USER_TYPE}/change-password`;
export const VERIFY_OTP_API = `${USER_TYPE}/verify-otp`;
export const USER_INFO_API = `${USER_TYPE}`;
export const ONBOARDING_API = `${USER_TYPE}/profile-complete`;

/**
 *@COMMON_APIS
 */
export const COMMON_BASE = `${USER_SERVICE}/common`;
export const GET_SCHOOL_EVENT_TYPES = `${COMMON_BASE}/get-school-event-type`;

/**
 * @Category Relate End Points
 */

export const CATEGORY = `${USER_SERVICE}/category`;
export const CATEGORY_LIST_API = `${CATEGORY}/get-category-list`;
export const CATEGORY_LIST_API_FOR_CREATE_EVENT = `${CATEGORY}/category-list-for-event-creation`;
export const UPDATE_CATEGORY_STATUS_API = `${CATEGORY}/update-status-category`;
export const CATEGORY_DETAIL_API = `${CATEGORY}/get-category-detail`;
export const CATEGORY_UPDATE_API = `${CATEGORY}/update-category`;
export const CATEGORY_CREATE_API = `${CATEGORY}/create-category`;
export const POPULAR_CATEGORIES_LIST_API = `${CATEGORY}/get-popular-category-list`;
export const MARK_UN_MARK_POPULAR_CATEGORY_API = `${CATEGORY}/mark-as-popular-category`;

/**
 * @SUBCATEGORY Relate End Points
 */

export const SUBCATEGORY = `${USER_SERVICE}/subcategory`;
export const SUBCATEGORY_LIST_API = `${SUBCATEGORY}/get-subcategory-list`;
export const SUBCATEGORY_LIST_API_FOR_CREATE_EVENT = `${SUBCATEGORY}/sub-category-list-for-event-creation`;
export const UPDATE_SUBCATEGORY_STATUS_API = `${SUBCATEGORY}/update-subcategory-status`;
export const SUBCATEGORY_DETAIL_API = `${SUBCATEGORY}/get-sub-category-detail`;
export const ASSOCIATEDCATEGORY_LIST_API = `${SUBCATEGORY}/associate-category-list`;
export const SUBCATEGORY_UPDATE_API = `${SUBCATEGORY}/update-subcategory`;
export const SUBCATEGORY_CREATE_API = `${SUBCATEGORY}/create-subcategory`;
export const SUBCATEGORY_DELETE_API = `${SUBCATEGORY}/delete-subcategory`;
export const POPULAR_SUBCATEGORIES_LIST_API = `${SUBCATEGORY}/popular-sub-category-list`;
export const MARK_UN_MARK_POPULAR_SUBCATEGORY_API = `${SUBCATEGORY}/mark-as-popular-sub-category`;

/**
 * @TAG_MANAGEMENT Relate End Points
 */

export const TAG = `${USER_SERVICE}/tag`;
export const TAG_LIST_API = `${TAG}/get-tag-list`;
// export const ASSOCIATEDCATEGORY_LIST_API = `${SUBCATEGORY}//associate-category-list`;
export const TAG_UPDATE_API = `${TAG}/update-tag`;
export const TAG_CREATE_API = `${TAG}/create-tag`;
export const TAG_DELETE_API = `${TAG}/delete-tag`;

/**
 *  @ORDER related endpoints
 */

export const ORDER_LISTING_API = `${ORDER_BASE}/order-list`;
export const EVENT_ORDER_LISTING_API = `${ORDER_BASE}/event-order-list`;
export const ORDER_DETAILS_API = `${ORDER_BASE}/order-detail`;
export const ORDER_EVENT_DETAILS_API = `${ORDER_BASE}/event-detail-for-order`;
export const ORDER_TICKET_LISTING_API = `${ORDER_BASE}/order-ticket-list`;
export const REFUND_REASON_API = `${COMMON_BASE}/get-reason-list`;
export const DECLINE_REFUND_API = `${ORGANIZER_SERVICE}/organizer/decline-refund-request`;
export const REFUND_PROCESSED_API = `${ORGANIZER_SERVICE}/organizer/refund-processed`;
export const MANAGE_ATTENDEE_LIST_API = `${ORDER_BASE}/event-attendees-list`;

/**
 * @Event Relate End Points
 */

export const EVENT = `${USER_SERVICE}/event`;
export const EVENT_LIST_API = `${EVENT}/get-event-list`;
export const EVENT_DETAIL_API = `${EVENT}/get-event-detail`;
export const EVENT_STATUS_UPDATE_API = `${EVENT}/update-event-status`;
export const CREATE_SINGLE_EVENT_API = `${EVENT}/create-single-event`;
export const CREATE_CLASS_CREATOR_EVENT_API = `${EVENT}/create-class-creator`;
export const UPDATE_CLASS_CREATOR_API = `${EVENT}/update-class-creator`;
export const UPDATE_SINGLE_EVENT_API = `${EVENT}/update-single-event`;
export const CREATE_MULTIPLE_EVENT_API = `${EVENT}/create-multiple-event`;
export const UPDATE_MULTIPLE_EVENT_API = `${EVENT}/update-multi-event`;
export const UPDATE_EVENT_TICKET_SCANNER_ORGANIZER_API = `${EVENT}/update-organizer-and-scanners`;
export const EVENT_DETAIL_FOR_EDIT_EVENT_API = `${EVENT}/event-detail-for-edit-event`;
export const DELETE_EVENT_API = `${EVENT}/delete-event`;
export const CHECK_EDIT_AND_DELETE_EVENT_AVAILABILITY = `${EVENT}/check-event-edit-delete-availability`;
export const EVENT_REGISTERED_USER_LIST = `${EVENT}/event-registered-users`;
export const EVENT_SAVE_AS_DRAFT_API = `${EVENT}/save-as-draft`;
export const REMOVE_EVENT_FROM_DRAFT_API = `${EVENT}/remove-from-draft`;

export const CANCEL_EVENT_API = `${EVENT}/cancel-event`;
export const ARCHIVE_OR_DELETE_EVENT_API = `${EVENT}/event-action`;

/**
 *  @ORGANIZATION related endpoints
 */

export const ORGANIZATION = `${USER_SERVICE}/user`;
export const ORGANIZATION_LISTING_API = `${ORGANIZATION}/organization-list-for-create-event`;

// export const TAG_CREATE_API = `${TAG}/create-tag`;
// export const TAG_DELETE_API = `${TAG}/delete-tag`;

/**
 * @BANNER_MANAGEMENT Relate End Points
 */

export const BANNER = `${USER_SERVICE}/banner`;
export const BANNER_LIST_API = `${BANNER}/get-banner-list`;
export const BANNER_STATUS_UPDATE_API = `${BANNER}/update-banner-status`;
export const BANNER_CREATE_API = `${BANNER}/create-banner`;
export const BANNER_DELETE_API = `${BANNER}/delete-banner`;
export const BANNER_DETAIL_API = `${BANNER}/get-banner-detail`;
export const BANNER_UPDATE_API = `${BANNER}/update-banner`;

/**
 * @PROFILE_MANAGEMENT
 */

export const PROFILE = `${USER_SERVICE}/admin`;
export const PROFILE_DETAIL = `${PROFILE}/get-detail`;
export const UPDATE_PROFILE = `${PROFILE}/update-profile`;
export const UPDATE_PASSWORD = `${PROFILE}/change-password`;

/**
 * @CONTENT_MANAGEMENT
 */

export const CONTENT = `${USER_SERVICE}/content`;
export const CONTENT_DETAIL_API = `${CONTENT}/get-content-detail`;
export const CONTENT_UPDATE_API = `${CONTENT}/update-content`;

export const FAQ = `${USER_SERVICE}/faq`;
export const FAQ_LISTING_API = `${FAQ}/get-faqs`;
export const FAQ_UPDATE_API = `${FAQ}/update-faq`;
export const FAQ_UPDATE_STATUS_API = `${FAQ}/update-faq-status`;
export const FAQ_ADD_API = `${FAQ}/add-faqs`;
export const FAQ_DELETE_API = `${FAQ}/delete-faq`;

/**
 * @EVENT_ORGANIZER
 */
export const EVENT_ORGANIZER = `${USER_SERVICE}/user`;
export const EVENT_ORGANIZER_LIST_API = `${EVENT_ORGANIZER}/get-organizer-list`;
export const EVENT_ORGANIZER_DETAIL_API = `${EVENT_ORGANIZER}/get-user-detail`;
export const EVENT_ORGANIZER_STATUS_UPDATE_API = `${EVENT_ORGANIZER}/update-user-status`;
export const EVENT_ORGANIZER_CREATED_EVENT_LIST_API = `${EVENT_ORGANIZER}/get-organizer-created-event-list`;

/**
 * @USER_MANAGEMENT
 */
export const USER = `${USER_SERVICE}/user`;
export const USER_LIST_API = `${USER}/get-user-list`;
export const USER_ORGANIZER_LIST_API = `${USER}/get-user-organizer-list`;
export const USER_BOOKED_EVENT_LIST_API = `${USER_SERVICE}/${BOOKING}/get-user-booking-list`;
export const USER_BOOKED_EVENT_DETAIL_API = `${USER_SERVICE}/${BOOKING}/get-booking-detail`;
export const USER_DETAIL_API = `${USER}/get-user-detail`;
export const USER_STATUS_UPDATE_API = `${USER}/update-user-status`;
export const GET_PREFERRED_USER_LIST_API = `${USER}/get-preferred-user-list`;

/**
 * @DASHBOARD
 */

export const DASHBOARD = `${USER_SERVICE}/dashboard`;
export const DASHBOARD_COUNT_API = `${DASHBOARD}/get-dashboard-count`;
export const DASHBOARD_USER_AND_ORGANIZER_API = `${DASHBOARD}/get-recent-users-organizers`;
export const DASHBOARD_BOOKING_EARNING_API = `${USER_SERVICE}/${BOOKING}/get-booking-earning`;

/**
 * SUB_ADMIN
 */

export const SUB_ADMIN = `${USER_SERVICE}/${ADMIN_BASE}`;
export const CREATE_SUB_ADMIN_API = `${SUB_ADMIN}/create-sub-admin`;
export const GET_SUB_ADMIN_LIST_API = `${SUB_ADMIN}/get-sub-admin-list`;
export const GET_SUB_ADMIN_DETAIL_API = `${SUB_ADMIN}/get-sub-admin-detail`;
export const UPDATE_SUB_ADMIN_STATUS_API = `${SUB_ADMIN}/update-sub-admin-status`;
export const UPDATE_SUB_ADMIN_DETAIL_API = `${SUB_ADMIN}/update-sub-admin`;
export const DELETE_SUB_ADMIN_API = `${SUB_ADMIN}/delete-sub-admin`;

/**
 * NOTIFICATION
 */

export const GET_NOTIFICATION_LIST = `${USER_SERVICE}/${NOTIFICATION_BASE}/get-notification-list`;
export const GET_NOTIFICATION_DETAIL = `${USER_SERVICE}/${NOTIFICATION_BASE}/get-notification-detail`;
export const SEND_NOTIFICATION = `${USER_SERVICE}/${NOTIFICATION_BASE}/send-push-notification`;

/**
 * CLASS CREATOR
 */
export const GET_BUSINESS_TYPE = `${COMMON_BASE}/get-class-type`;

/**
 *  @ORGANIZATION related endpoints
 */

export const ALL_ORGANIZATION = `${APP_USER_SERVICE}/get-organizations`;
// export const ALL_ORGANIZATION_LISTING_API = `${ALL_ORGANIZATION}/panel-organization-list`;
export const ORGANIZATION_USERS_LISTING_API = `${APP_USER_SERVICE}/organization-user-list`;
export const ORGANIZATION_DETAIL_API = `${APP_USER_SERVICE}/get-organization-detail`;
export const CREATE_ORGANIZATION_API = `${APP_USER_SERVICE}/create-organization`;
export const UPDATE_ORGANIZATION_API = `${APP_USER_SERVICE}/update-organization`;
export const UPDATE_REFUND_SETTINGS_API = `${APP_USER_SERVICE}/update-refund-setting`;
export const UPDATE_TICKET_SETTINGS_API = `${APP_USER_SERVICE}/update-ticket-setting`;

export const FAVORITE = `${USER_SERVICE}/favorite-user`;
export const GET_FAVORITE_USER_DETAILS_API = `${FAVORITE}/favorite-user-detail`;
export const GET_FAVORITE_USER_STATUS_API = `${FAVORITE}/favorite-user-update-status`;
export const GET_FAVORITE_USER_EVENT_LIST_API = `${FAVORITE}/favorite-user-event-list`;
export const UNLINK_USER_FORM_EVENT_API = `${FAVORITE}/unlink-user-from-event`;
export const UPDATE_TAX_EXEMPTION_DETAILS = `${APP_USER_SERVICE}/update-tax-excemption-verification-status`;
export const CREATE_ORGANIZER_ACCOUNT = `${PAYMENT_SERVICE}payment/create-organizer-account`;

export const SEND_USER_INVITE_API = `${FAVORITE}/send-invite`;
export const SEARCH_ORGANIZER_USER_LIST_API = `${ORGANIZATION}/organizer-list-for-create-event`;
export const MARK_FAVORITE_USER_API = `${FAVORITE}/add-favorite-user`;
export const DELETE_FAVORITE_USER = `${FAVORITE}/delete-favorite-user`;
export const TICKET_SCANNER_FOR_INVITE_USER = `${USER}/ticket-scanner-list-for-invite`;
export const ORGANIZER_FOR_INVITE_USER = `${USER}/organizer-list-for-invite`;

/**
 * CLAIM EVENT
 */

// export const CLAIM = `${USER_SERVICE}/claim`;
export const CLAIM_EVENT_REQUEST_LIST_API = `${EVENT}/get-claim-requests`;
export const DENY_EVENT_REQUEST_API = `${EVENT}/claim-request-denied`;
export const ACCEPT_CLAIM_EVENT_REQUEST_API = `${EVENT}/claim-request-accepted`;



/**
 * @VERSIONS Relate End Points
 */

export const VERSIONS_LIST_API = `${COMMON_BASE}/get-version-list`;
export const CREATE_VERSION_API = `${COMMON_BASE}/add-version`;
export const DELETE_VERSION_API = `${COMMON_BASE}/delete-version`;
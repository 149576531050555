import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { BehaviorSubject, Observable, Subject, firstValueFrom, take, timer } from 'rxjs';
import { Router } from '@angular/router';
import { DASHBOARD, LOGIN } from 'src/app/constants/routes';
import { PROFILE_DETAIL, USER_INFO_API } from 'src/app/constants/api-end-points';
import { email, userId, } from 'src/app/constants/storage-keys';
import { ApiResponse } from 'src/app/constants/interfaces';
import { ToastService } from 'src/app/components/toast-notification/toast.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  public isLoggedIn$: Observable<boolean> = this.isLoggedInSubject.asObservable();

  private logoutTimer: Observable<number>;
  profileDetail: any;
  profileData = new Subject<any>();

  constructor(private _http: HttpService, private _router: Router, private _toast: ToastService) {
    this.initLogoutTimer();

  }

  setUserToken(token: any) {
    localStorage.setItem("session_token", token);
  }

  get token() {
    return localStorage.getItem("session_token");
  }

  get userId() {
    return atob(localStorage.getItem(userId));
  }

  logout() {
    localStorage.removeItem(email);
    localStorage.removeItem('session_token');
    // localStorage.clear();
    setTimeout(() => {
      this.profileDetail = null;
    }, 1000); // this is used for avoid random reflection on side bar if logout
    this._router.navigate([LOGIN.fullUrl]);
    this.isLoggedInSubject.next(false);

  }

  async loginSuccessfully(response: any, responeFrom = "") {
    const data = response.result;
    this.setUserToken(data.accessToken);
    localStorage.setItem(userId, btoa(data._id));
    this._router.navigate([DASHBOARD.fullUrl]);
    this._toast.success(response.message);
    this.isLoggedInSubject.next(true);
    this.resetLogoutTimer();
  }

   initLogoutTimer() {
    const logoutTime = 30 * 60 * 1000; // 30 minutes in milliseconds
    this.logoutTimer = timer(logoutTime).pipe(take(1));
    this.logoutTimer.subscribe(() => {
      this.logout();
      this._toast.error('Session Expired !');
    });
  }

   resetLogoutTimer() {
    // Reset the timer when the user interacts with the application
    this.logoutTimer.subscribe(() => {
      // Reset the timer or perform any other necessary actions
      this.initLogoutTimer();
    });
  }



  getProfileDetail(showLoader = true) {
    return new Promise((resolve, reject) => {
      if (this.profileDetail) {
        resolve(this.profileDetail);
      } else {

        firstValueFrom(this._http
          .get(PROFILE_DETAIL))
          .then(
            (response: any) => {
              if (response?.result?.status === "blocked") {
                this.logout();
                reject();
              }

              this.profileDetail = response.result;
              resolve(this.profileDetail);
            },
            (error) => {
              reject(error);
              this.logout();

            }
          )
          .catch((error) => {
            this.logout();
          });
      }
    });
  }

  async updateProfileDetails(showLoader = true) {
    try {
      const updatedProfile: ApiResponse<any> = await firstValueFrom(
        this._http.get(USER_INFO_API, "", showLoader)
      );
      if (updatedProfile.data.status === "blocked") {
        this.logout();
      }
      if (updatedProfile.status == 200) {
        this.profileDetail = updatedProfile.data;
      }
    } catch (error) {
      this.logout();
    }
  }

  deviceDetail(info?: number) {
    /*---1=device_token, 2=deviceId, 3=platform---*/
    // switch (info) {
    //   case 1:
    //     let deviceToken = this.attachDeviceToken();
    //     return deviceToken;
    //   case 2:
    //     let deviceId = this.randomDeviceId();
    //     return deviceId;
    //   case 3:
    //     return "3";
    //   case 4:
    //     return this.getTimeZone();
    // }
  }

  attachDeviceToken() {
    return (Date.now() + Math.floor(Math.random() * 1000000) + 1).toString();
  }

  randomDeviceId() {
    return window.navigator.userAgent.replace(/\D+/g, "");
  }

  getTimeZone() {
    let date = new Date();
    let offset = date.getTimezoneOffset() * -1;
    return offset * 60 * 1000;
  }


}
